const themeStyles = {
  borders: ["2px"],
  borderStyles: ["solid", "dashed"],
  borderWidths: [1, 4],
  colors: {
    text: "#000000",
    textWhite: "#FFFFFF",
    background: "#FFFFFF",
    primary: "#FF8300",
    secondary: "#1A6FC4",
    blue: "#12458F",
    darkBlue: "#21468B",
    muted: "#AFAFAF",
    highlight: "#BD2C34",
    gray: "#5A5A5A",
    darkGray: "#737373",
    accent: "#FF6600",
    darken: "#F7F7F7",
  },
  fonts: {
    body: "Open Sans, sans-serif",
    heading: "PT Serif, serif",
    monospace: "Menlo, monospace",
  },
  fontSizes: [13, 15, 17, 18, 21, 24, 36, 48],
  fontWeights: {
    body: 400,
    regular: 600,
    heading: 700,
    bold: 700,
  },
  lettingSpacings: {},
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  radii: [],
  shadows: [
    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
    "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
    "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)",
  ],
  sizes: {
    container: 1200,
  },
  space: [
    0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 80, 100, 128, 150, 180, 200, 256, 512,
  ],
  zIndices: [],
  // These are not currently set and are set to 3 breakpoints by default, by using
  // the breakpoints below, you can have more control over when page
  breakpoints: ["768px", "1024px", "1200px", "1450px"],
  styles: {
    root: {
      fontSize: [1, 3],
      fontFamily: "body",
      lineHeight: "body",
    },
    p: {
      color: "text",
      lineHeight: "body",
      py: 0,
      code: {
        backgroundColor: "darken",
        color: "text",
        padding: 1,
      },
    },
    small: {
      fontSize: 0,
    },
    a: {
      color: "primary",
    },
    h1: {
      color: "text",
      fontSize: [4, 6],
      textTransform: "uppercase",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      // marginBottom: 5,
      a: {
        color: "inherit",
      },
    },
    h2: {
      color: "text",
      fontSize: [4, 6],
      fontWeight: "text",
      lineHeight: "heading",
      marginTop: 0,
      // marginBottom: 4,
      a: {
        color: "inherit",
      },
    },
    h3: {
      color: "text",
      fontSize: [2, 5],
      textTransform: "uppercase",
      fontWeight: "heading",
      fontFamily: "body",
      lineHeight: "heading",
      marginTop: 0,
      // marginBottom: 3,
      a: {
        color: "inherit",
      },
    },
    h4: {
      color: "text",
      fontSize: 4,
      fontFamily: "body",
      fontWeight: "heading",
      lineHeight: "heading",
      marginTop: 0,
      marginBottom: 3,
      a: {
        color: "inherit",
      },
    },
    // h5: {
    //   color: "text",
    //   fontSize: 3,
    //   fontWeight: "heading",
    //   lineHeight: "heading",
    //   marginTop: 0,
    //   marginBottom: 3,
    //   a: {
    //     color: "inherit"
    //   }
    // },
    // h6: {
    //   color: "text",
    //   fontSize: 2,
    //   fontWeight: "heading",
    //   lineHeight: "heading",
    //   marginTop: 0,
    //   marginBottom: 3
    // },
    fancyUnderline: {
      color: "inherit",
      display: "inline-block",
      fontSize: [4, 6],
      fontWeight: "body",
      lineHeight: "heading",
      mb: ["9px", "13px"],
      pb: 1,
      position: "relative",
      "&::before": {
        background:
          "linear-gradient(to bottom right, #FF8300, #FF8300 50%, transparent calc(50% + 1px), transparent 100%)",
        content: '""',
        height: [9, 13],
        left: 0,
        position: "absolute",
        top: "100%",
        width: "75%",
      },
    },
    img: {},
    pre: {
      borderColor: "gray",
      borderStyle: 1,
      borderWidth: 0,
      backgroundColor: "darken",
      color: "text",
      lineHeight: "body",
      overflow: "auto",
      padding: 3,
      code: {
        backgroundColor: "darken",
        color: "text",
        lineHeight: "body",
      },
    },
    ol: {
      color: "text",
    },
    ul: {
      color: "text",
    },
    li: {
      color: "text",
      lineHeight: "body",
    },
    blockquote: {
      borderLeftColor: "primary",
      borderLeftStyle: 0,
      borderLeftWidth: 1,
      padding: 2,
      backgroundColor: "darken",
      p: {
        margin: 0,
      },
    },
    hr: {
      border: 0,
      borderStyle: 0,
      color: "muted",
      marginTop: 5,
      marginBottom: 5,
    },
    em: {},
    table: {
      color: "text",
      border: 0,
      borderStyle: 0,
      borderColor: "gray",
      borderCollapse: "collapse",
      mb: 3,
    },
    tr: {},
    th: {
      backgroundColor: "darken",
      border: 0,
      borderStyle: 0,
      borderColor: "gray",
      padding: 2,
    },
    td: {
      border: 0,
      borderStyle: 0,
      borderColor: "gray",
      padding: 2,
    },
    strong: {},
    del: {},
    b: {},
    i: {},
    progress: {
      backgroundColor: "darken",
      color: "primary",
      secondary: {
        backgroundColor: "darken",
        color: "secondary",
      },
    },
    donut: {
      primary: {
        color: "primary",
      },
      secondary: {
        color: "secondary",
      },
    },
    spinner: {
      primary: {
        color: "primary",
      },
      secondary: {
        color: "secondary",
      },
    },
  },
  box: {
    skewed: {
      width: "auto",
      px: 9,
      py: 2,
      bg: "primary",
      color: "textWhite",
      transform: "rotate(-10deg) skew(-15deg)",
    },
  },
  flex: {},
  grids: {},
  buttons: {
    primary: {
      backgroundColor: "background",
      borderColor: "primary",
      borderRadius: 0,
      borderStyle: "solid",
      borderWidth: "3px",
      color: "primary",
      cursor: "pointer",
      display: "inline-block",
      fontSize: [1, 3],
      px: 5,
      py: 2,
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      transition: "all 0.3s",
      whiteSpace: "nowrap",
      ".triangle": {
        borderColor: "transparent transparent transparent #FF8300",
      },
      "&:hover": {
        backgroundColor: "primary",
        color: "textWhite",
        ".triangle": {
          borderColor: "transparent transparent transparent #FFFFFF",
        },
      },
      '&[aria-selected="true"]': {
        backgroundColor: "primary",
        color: "textWhite",
      },
    },
    secondary: {
      backgroundColor: "background",
      borderColor: "secondary",
      borderRadius: 0,
      borderStyle: "solid",
      borderWidth: "3px",
      color: "secondary",
      cursor: "pointer",
      display: "inline-block",
      fontSize: [1, 3],
      px: 5,
      py: 2,
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      transition: "all 0.3s",
      whiteSpace: "nowrap",
      ".triangle": {
        borderColor: "transparent transparent transparent #1A6FC4",
      },
      "&:hover": {
        backgroundColor: "secondary",
        color: "textWhite",
        ".triangle": {
          borderColor: "transparent transparent transparent #FFFFFF",
        },
      },
    },
    darkBlue: {
      backgroundColor: "darkBlue",
      borderColor: "background",
      borderRadius: 0,
      borderStyle: "solid",
      borderWidth: "3px",
      color: "textWhite",
      cursor: "pointer",
      display: "inline-block",
      fontSize: [1, 3],
      px: 5,
      py: 2,
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      transition: "all 0.3s",
      whiteSpace: "nowrap",
      ".triangle": {
        borderColor: "transparent transparent transparent #FFFFFF",
      },
      "&:hover": {
        backgroundColor: "background",
        color: "darkBlue",
        ".triangle": {
          borderColor: "transparent transparent transparent #21468B",
        },
      },
    },
    arrowIcon: {
      backgroundColor: "background",
      borderRadius: 0,
      color: "primary",
      cursor: "pointer",
      display: "inline-block",
      fontSize: [2, 5],
      fontWeight: "bold",
      px: [4, null, 7],
      py: [3, null, 4],
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
      transition: "all 0.3s",
      whiteSpace: "nowrap",
      ".triangle": {
        borderColor: "transparent transparent transparent #FF8300",
      },
      "&:hover": {
        backgroundColor: "primary",
        color: "textWhite",
        ".triangle": {
          borderColor: "transparent transparent transparent #FFFFFF",
        },
      },
    },
    submit: {
      backgroundColor: "primary",
      borderRadius: 0,
      color: "textWhite",
      cursor: "pointer",
      display: "inline-block",
      fontSize: [2, 5],
      fontWeight: "bold",
      px: [4, null, 8],
      py: [3, null, 4],
      textAlign: "center",
      textDecoration: "none",
      textTransform: "uppercase",
      transition: "all 0.3s",
      whiteSpace: "nowrap",
      ".triangle": {
        borderColor: "transparent transparent transparent #FFFFFF",
      },
      "&:hover": {
        backgroundColor: "background",
        color: "primary",
        ".triangle": {
          borderColor: "transparent transparent transparent #FF8300",
        },
      },
    },
    close: {
      color: "text",
      cursor: "pointer",
      height: [30, null, 40],
      p: 0,
      width: [30, null, 40],
      svg: {
        height: "100%",
        width: "100%",
      },
    },
    icon: {},
    menu: {},
  },
  text: {
    text: {
      display: "block",
    },
    heading: {},
    paragraph: {
      py: 2,
    },
  },
  links: {
    headerLink: {
      color: "textWhite",
      cursor: "pointer",
      fontSize: [1, null, null, null, 3],
      fontWeight: "body",
      lineHeight: "25px",
      textDecoration: "none",
      textTransform: "uppercase",
      transition: "all 0.3s",
      "&:hover": {
        color: "primary",
      },
    },
    nav: {
      color: "primary",
    },
    barLink: {
      color: "textWhite",
      display: "inline-block",
      fontFamily: "body",
      fontSize: [2, null, 5],
      fontWeight: "bold",
      textDecoration: "none",
    },
    footerLink: {
      color: "textWhite",
      fontFamily: "body",
      fontSize: [1, null, 3],
      fontWeight: "body",
      textDecoration: "none",
      textTransform: "capitalize",
    },
  },
  images: {
    avatar: {},
  },
  cards: {
    primary: {
      color: "text",
      boxShadow: 0,
      backgroundColor: "darken",
    },
  },
  layout: {
    container: {
      maxWidth: 1200,
      px: 3,
    },
    narrow: {
      maxWidth: 960,
      margin: "0 auto",
      px: 3,
    },
  },
  forms: {
    label: {
      alignItems: "center",
      color: "textWhite",
      fontSize: [2, null, 5],
      fontWeight: "bold",
      marginBottom: 2,
      textTransform: "uppercase",
    },
    input: {
      backgroundColor: "background",
      border: "4px solid transparent",
      borderRadius: 0,
      color: "text",
      fontSize: [1, null, 3],
      fontWeight: "body",
      outline: "none",
      px: 3,
      py: 2,
      transition: "all 0.3s",
      "&::placeholder": {
        color: "#B5B5B5",
        fontSize: [1, null, 3],
        fontWeight: "body",
      },
      "&:required:focus": {
        border: "4px solid",
        borderColor: "primary",
      },
    },
    select: {
      color: "text",
    },
    textarea: {
      backgroundColor: "background",
      border: "4px solid transparent",
      borderRadius: 0,
      color: "text",
      fontFamily: "body",
      fontSize: [1, null, 3],
      fontWeight: "body",
      outline: "none",
      px: 3,
      py: 2,
      transition: "all 0.3s",
      "&::placeholder": {
        color: "#B5B5B5",
        fontSize: [1, null, 3],
        fontWeight: "body",
      },
      "&:required:focus": {
        border: "4px solid",
        borderColor: "primary",
      },
    },
    slider: {
      color: "primary",
      backgroundColor: "muted",
    },
    radio: {},
    checkbox: {},
  },
  badges: {
    primary: {
      backgroundColor: "primary",
    },
    secondary: {
      backgroundColor: "secondary",
      color: "background",
    },
  },
  alerts: {
    primary: {
      backgroundColor: "primary",
    },
    secondary: {
      backgroundColor: "secondary",
      color: "background",
    },
  },
  messages: {
    primary: {
      color: "text",
      backgroundColor: "darken",
      borderLeftColor: "primary",
    },
    secondary: {
      color: "text",
      backgroundColor: "darken",
      borderLeftColor: "secondary",
    },
  },
};

export default themeStyles;
